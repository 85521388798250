<template>
    <li
        class="lazy-hover product-list-item font-roboto-c"
        itemscope
        itemtype="https://schema.org/Product"
        @click="$emit('click', $event)"
    >
        <meta content="Uma Penca" itemprop="brand" />
        <meta :content="product.id ? product.id : product._id" itemprop="sku" />

        <a :content="product.link" :href="productLink" itemprop="url" target="_self">
            <div
                :class="{
                    'aspect-xs-4-5': shorterImage,
                    'aspect-shirt': !shorterImage,
                }"
                class="product-list-item-image-hover rounded overflow-hidden aspect bg-default-1"
                rel="1"
            >
                <div class="pos-absolute pos-all">
                    <img
                        :alt="product.name"
                        :class="{
                            'h-full': shorterImage && product.type.id !== 1,
                        }"
                        :data-src="image"
                        border="0"
                        class="product-list-item-photo lazy lazy-fade w-full"
                        itemprop="image"
                        src=""
                    />
                    <img
                        v-if="!hideArtImage"
                        :alt="'Estampa ' + product.name"
                        :data-src="imageArt"
                        border="0"
                        class="product-list-item-art lazy lazy-fade"
                        src=""
                    />
                    <img
                        v-if="!hideArtImage && selectedFabric"
                        :alt="'Estampa ' + product.name"
                        :src="imageArt"
                        border="0"
                        class="product-list-item-art"
                    />
                </div>

                <div
                    v-if="fabricsToPreview"
                    class="product-list-item-colors"
                    @mouseleave="selectedFabric = null"
                >
                    <div
                        v-for="fabric in fabricsToPreview"
                        :style="{ 'background-color': fabric.hexa }"
                        class="product-list-item-color"
                        @mouseenter="selectedFabric = fabric"
                    ></div>
                </div>

                <div class="pos-absolute pos-bottom pos-left ml mb">
                    <ProductListItemSeal
                        :small="small"
                        :product="product"
                        :show-discount="!!options.displayPercent"
                        :show-promo="displayPromoBadge"
                    />
                </div>
            </div>

            <slot name="below-image"></slot>

            <p
                v-if="!options || options.displayName"
                class="product-list-item-title"
                itemprop="name"
            >
                {{ product.name }}
            </p>
            <meta v-else :content="product.name" itemprop="name" />

            <div
                class="product-list-item-info"
                itemprop="offers"
                itemscope
                itemtype="http://schema.org/Offer"
            >
                <link :href="product.link" itemprop="url" />

                <link href="http://schema.org/NewCondition" itemprop="itemCondition" />

                <template v-if="options && options.displayPrice && product.in_stock">
                    <ProductCatalogPrice
                        :options="options"
                        :product="product"
                        :show-promo="displayPromoBadge"
                    />
                </template>
                <template v-else-if="options && options.displayPrice">
                    <meta
                        :content="product.price_min > 0 ? product.price_min : product.price"
                        itemprop="price"
                    />
                    <meta content="BRL" itemprop="priceCurrency" />
                    <p>Indisponível</p>
                </template>
                <template v-else>
                    <meta
                        :content="product.price_min > 0 ? product.price_min : product.price"
                        itemprop="price"
                    />
                    <meta content="BRL" itemprop="priceCurrency" />
                </template>
                <link
                    :href="
                        'http://schema.org/' +
                        (product.quantity > 0 || product.virtual_stock ? 'InStock' : 'OutOfStock')
                    "
                    itemprop="availability"
                />
            </div>

            <slot />
        </a>

        <slot name="outside-link" />
    </li>
</template>

<script>
    import { getProductThumbLink } from '../../common/common';
    import ProductCatalogPrice from './ProductCatalogPrice';
    import ProductListItemSeal from './ProductListItemSeal';

    export default {
        name: 'ProductListItem',

        components: { ProductListItemSeal, ProductCatalogPrice },

        props: {
            product: {
                type: Object,
                required: true,
            },
            options: {
                type: Object,
                default: () => ({}),
            },
            gender: {
                type: Number,
                default: 0,
            },
            fabric: {
                type: Number,
                default: 0,
            },
            selectedFilters: {
                type: Object,
            },
            shorterImage: Boolean,
            small: Boolean,
        },

        data() {
            return {
                selectedFabric: null,
            };
        },

        computed: {
            productLink() {
                if (this.product.is_customizable) {
                    return (
                        this.$scope.URL +
                        'produtos-personalizados/' +
                        (this.product.name + '-' + this.product.id)
                            .toLowerCase()
                            .replaceAll(' ', '-')
                    );
                }

                let url = new URL(this.$scope.URL + this.product.link.substr(1));

                /*  Descomentar caso queira filtrar o gênero da imagem principal na home
                if (this.product.img_cover_gender) {
                    if (
                        ['M', 'F'].indexOf(this.product.img_cover_gender) !== -1
                    ) {
                        url.searchParams.set(
                            'gender',
                            this.product.img_cover_gender
                        );
                    }
                }*/

                if (this.selectedFilters) {
                    if (['M', 'F'].indexOf(this.selectedFilters.gender) !== -1) {
                        url.searchParams.set('gender', this.selectedFilters.gender);
                    }

                    if (this.selectedFilters.inPhysicalStock === 1) {
                        url.searchParams.set(
                            'inPhysicalStock',
                            this.selectedFilters.inPhysicalStock
                        );
                    }

                    if (this.selectedFilters.size) {
                        url.searchParams.set('size', this.selectedFilters.size);
                    }
                }

                if (this.selectedFabric) {
                    url.searchParams.set('fabric', this.selectedFabric.id);
                }

                return url.toString();
            },

            imageArt() {
                if (parseInt((this.product.type || {}).id) === 6) return this.image;

                try {
                    if (this.hideArtImage) {
                        return null;
                    }

                    return getProductThumbLink(
                        this.product.img_thumb,
                        this.product.img_thumb_png,
                        this.selectedFabric ? this.selectedFabric.id : this.fabric,
                        {
                            auto: 'compress,format',
                            q: 65,
                            fit:
                                this.product.is_clothing || [17, 6].includes(this.product.type.id)
                                    ? 'crop'
                                    : 'fill',
                            bg:
                                this.product.is_clothing || [17, 6].includes(this.product.type.id)
                                    ? ''
                                    : 'FFFFFF',
                            w:
                                this.options && this.options.imageWidth
                                    ? this.options.imageWidth
                                    : null,
                            h:
                                this.options && this.options.imageHeight
                                    ? this.options.imageHeight
                                    : null,
                        }
                    );
                } catch (err) {
                    return console.warn(err);
                }
            },

            image() {
                try {
                    if (
                        this.options &&
                        this.options.displayModel !== undefined &&
                        !this.options.displayModel &&
                        this.imageArt
                    ) {
                        return this.imageArt;
                    }

                    let url =
                        this.gender === 2 && this.product.img_female != null
                            ? this.product.img_female
                            : this.gender === 1 && this.product.img_male != null
                            ? this.product.img_male
                            : this.product.img_cover;

                    return this.croppedImage(url);
                } catch (err) {
                    return null;
                }
            },

            hideArtImage() {
                return (
                    !this.options ||
                    !this.options.displayArt ||
                    parseInt((this.product.type || {}).id) === 6
                );
            },

            displayPromoBadge() {
                if (!this.$scope.IS_REGULAR_CUSTOMER) return false;

                return !this.product.promo_group;
            },

            fabricsToPreview() {
                if (!this.options.displayColors || (this.product.fabrics || []).length <= 1)
                    return null;
                return this.product.fabrics
                    .filter((f) => this.product.fabric_id !== f.id)
                    .slice(0, 5);
            },
        },

        methods: {
            croppedImage(url) {
                url = new URL(url);
                url.searchParams.set('auto', 'compress,format');
                url.searchParams.set('q', '65');
                url.searchParams.set('fit', 'crop');

                if (this.options && this.options.imageWidth) {
                    url.searchParams.set('w', this.options.imageWidth);
                }

                if (this.options && this.options.imageHeight) {
                    url.searchParams.set('h', this.options.imageHeight);
                }

                return url.toString();
            },
        },
    };
</script>
