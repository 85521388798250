<template>
    <li style="list-style: none">
        <a
            :href="url"
            class="catalog-filter-group-option d-block cursor-pointer text-no-decoration"
            @click="fetch"
        >
            <div :class="{ 'd-flex flex-align-center': hex }">
                <div v-if="hex">
                    <div
                        v-if="isSelected"
                        :style="`width: 1rem; height: 1rem; border-color: #${hex}`"
                        class="pos-relative rounded-full px-1 py-1 mr border border-solid border-2"
                    >
                        <div
                            :style="`width: .5rem; height: .5rem; background-color: #${hex}`"
                            class="pos-absolute pos-center rounded-full"
                        />
                    </div>
                    <div
                        v-else
                        :style="`width: 1rem; height: 1rem; background-color: #${hex}`"
                        class="pos-relative rounded-full px-1 py-1 mr"
                    />
                </div>
                <input
                    v-else
                    :id="radioId"
                    :checked="isSelected"
                    :name="radioGroup"
                    :value="value"
                    class="cr-radio"
                    type="radio"
                />
                <label
                    :for="radioId"
                    class="text-nowrap d-flex flex-align-center flex-item-grow cursor-pointer"
                >
                    <span class="mr">{{ label }}</span>
                    <div v-if="count" class="ml-auto badge-default-1">
                        {{ count }}
                    </div>
                </label>
            </div>
        </a>
    </li>
</template>

<script>
    import Radio from '@bit/chicorei.chicomponents.input.radio';
    import CatalogFilterLink from './CatalogFilterLink';

    export default {
        name: 'CatalogFilterGroupOption',

        extends: CatalogFilterLink,

        components: { Radio },

        inject: {
            filterGroup: {
                default() {
                    return {};
                },
            },
        },

        props: {
            hex: String,
            idPrefix: {
                type: String,
                required: true,
            },
        },

        mounted() {
            this.filterGroup.registerOption(this);
        },

        computed: {
            selectedValueInGroup() {
                return this.filterGroup.value();
            },

            isSelected() {
                return (this.selectedValueInGroup || null) === (this.value || null);
            },

            radioGroup() {
                return this.filterGroup.radioName().toLowerCase();
            },

            radioId() {
                return `${this.idPrefix}-${this.urlParam}-${this.value}`;
            },
        },
    };
</script>
